// src/pages/Contact.js
import React from 'react';
import { Box, Typography, Container, Grid, TextField, Button, Paper } from '@mui/material';
import Fade from 'react-reveal/Fade';

const Contact = () => {
    return (
        <Box>
            <Container sx={{ mt: 8 }}>
                <Typography variant="h3" align="center" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" align="center" paragraph>
                    Have questions, feedback, or need support? We’re here to help! Reach out to us through the form below or contact us directly via email or phone.
                </Typography>

                {/* Contact Form */}
                <Box sx={{ mt: 6 }}>
                    <Fade bottom>
                        <Container maxWidth="sm">
                            <Paper elevation={3} sx={{ p: 4 }}>
                                <Typography variant="h5" align="center" gutterBottom>
                                    Send Us a Message
                                </Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Your Name"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Your Email"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Subject"
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    label="Your Message"
                                    margin="normal"
                                />
                                <Box sx={{ textAlign: 'center', mt: 4 }}>
                                    <Button variant="contained" color="primary" sx={{ px: 5, py: 2 }}>
                                        Send Message
                                    </Button>
                                </Box>
                            </Paper>
                        </Container>
                    </Fade>
                </Box>

                {/* Contact Information */}
                <Box sx={{ mt: 8, textAlign: 'center' }}>
                    <Fade bottom>
                        <Typography variant="h5" gutterBottom>
                            Contact Information
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Email: <a href="mailto:support@yourwebsite.com" style={{ textDecoration: 'none', color: '#1976d2' }}>support@yourwebsite.com</a>
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Phone: +233553349407
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Address: University of Cape Coast
                        </Typography>
                    </Fade>
                </Box>

                {/* Social Media Links */}
                <Box sx={{ mt: 8, textAlign: 'center' }}>
                    <Fade bottom>
                        <Typography variant="h5" gutterBottom>
                            Follow Us
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                            <a href="https://facebook.com/yourprofile" target="_blank" rel="noopener noreferrer">
                                <img src="/icons/facebook-icon.png" alt="Facebook" width="40" />
                            </a>
                            <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
                                <img src="/icons/twitter-icon.png" alt="Twitter" width="40" />
                            </a>
                            <a href="https://linkedin.com/yourprofile" target="_blank" rel="noopener noreferrer">
                                <img src="/icons/linkedin-icon.png" alt="LinkedIn" width="40" />
                            </a>
                            <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
                                <img src="/icons/instagram-icon.png" alt="Instagram" width="40" />
                            </a>
                        </Box>
                    </Fade>
                </Box>

                {/* Map Section */}
                <Box sx={{ mt: 8 }}>
                    <Fade bottom>
                        <Typography variant="h5" align="center" gutterBottom>
                            Our Location
                        </Typography>
                        <Container maxWidth="md">
                            <Paper elevation={3}>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d243647.500000!2d-123.365644!3d48.428421!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzgnMjQnMDguMiJOIDEyM8KwMjEnNTcuOCJX!5e0!3m2!1sen!2sca!4v1621628479301!5m2!1sen!2sca"
                                    width="100%"
                                    height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>
                            </Paper>
                        </Container>
                    </Fade>
                </Box>
            </Container>
        </Box>
    );
};

export default Contact;
