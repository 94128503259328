// src/pages/About.js
import React from 'react';
import { Box, Typography, Container, Grid, Paper } from '@mui/material';
import Fade from 'react-reveal/Fade';

const About = () => {
    return (
        <Box>
            {/* Hero Section */}
            <Box 
                sx={{ 
                    backgroundImage: 'url(/about-hero-image.jpg)',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '40vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    textAlign: 'center',
                    padding: 4,
                }}
            >
                <Container>
                    <Typography variant="h3" gutterBottom>
                        About Us
                    </Typography>
                    <Typography variant="h5">
                        Learn more about our mission, vision, and what we seek to achieve.
                    </Typography>
                </Container>
            </Box>

            {/* Mission Section */}
            <Fade bottom>
                <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
                    <Container>
                        <Typography variant="h4" align="center" gutterBottom>
                            Our Mission
                        </Typography>
                        <Typography variant="body1" align="center" paragraph>
                            To provide a seamless and accurate grading system conversion service, empowering students and professionals worldwide to navigate educational systems with ease and confidence.
                        </Typography>
                    </Container>
                </Box>
            </Fade>

            {/* Vision Section */}
            <Fade bottom>
                <Box sx={{ py: 8, bgcolor: '#f9f9f9' }}>
                    <Container>
                        <Typography variant="h4" align="center" gutterBottom>
                            Our Vision
                        </Typography>
                        <Typography variant="body1" align="center" paragraph>
                            We envision a world where academic credentials are universally recognized, ensuring that everyone has access to education and career opportunities, regardless of where they are from.
                        </Typography>
                    </Container>
                </Box>
            </Fade>

            {/* Goals Section */}
            <Fade bottom>
                <Box sx={{ py: 8, bgcolor: 'background.paper' }}>
                    <Container>
                        <Typography variant="h4" align="center" gutterBottom>
                            Our Goals
                        </Typography>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item xs={12} md={4}>
                                <Paper 
                                    elevation={3} 
                                    sx={{ 
                                        p: 4, 
                                        textAlign: 'center',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                        }
                                    }}
                                >
                                    <Typography variant="h6">Global Reach</Typography>
                                    <Typography variant="body2">
                                        Expand our platform to cover more countries and grading systems, making it a global standard for academic conversions.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper 
                                    elevation={3} 
                                    sx={{ 
                                        p: 4, 
                                        textAlign: 'center',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                        }
                                    }}
                                >
                                    <Typography variant="h6">Accuracy and Reliability</Typography>
                                    <Typography variant="body2">
                                        Continuously improve our algorithms to ensure the highest level of accuracy and reliability in grading conversions.
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Paper 
                                    elevation={3} 
                                    sx={{ 
                                        p: 4, 
                                        textAlign: 'center',
                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                        }
                                    }}
                                >
                                    <Typography variant="h6">User Experience</Typography>
                                    <Typography variant="body2">
                                        Enhance user experience with intuitive design, responsive support, and seamless interactions across all devices.
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Fade>

            {/* Call to Action Section */}
            <Fade bottom>
                <Box sx={{ py: 8, bgcolor: '#f0f0f0', textAlign: 'center' }}>
                    <Container>
                        <Typography variant="h4" gutterBottom>
                            Ready to Explore?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Discover how our grading system converter can help you achieve your academic and career goals.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <a href="/services" style={{ color: '#1976d2', textDecoration: 'none', fontWeight: 'bold' }}>Learn More About Our Services</a>
                        </Typography>
                    </Container>
                </Box>
            </Fade>
        </Box>
    );
};

export default About;
