import React, { useState } from 'react';
import { Box, Typography, Button, Container, TextField, MenuItem, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Link } from 'react-router-dom';

const countries = ["USA", "Canada", "UK", "Australia", "Netherlands", "Germany", "Ghana", "Nigeria", /* Add more countries here */];
const gradingSystems = ["USA", "Canada", "UK", "Australia", "Netherlands", "Germany"];

const TranscriptUpload = () => {
    const [country, setCountry] = useState('');
    const [system, setSystem] = useState('');
    const [file, setFile] = useState(null);
    const [isConverting, setIsConverting] = useState(false);
    const [conversionComplete, setConversionComplete] = useState(false);

    const handleUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = () => {
        setIsConverting(true);
        setTimeout(() => {
            setIsConverting(false);
            setConversionComplete(true);
        }, 3000);
    };

    return (
        <Box 
            sx={{ 
                backgroundColor: '#ffffff', 
                borderRadius: '12px', 
                padding: '40px', 
                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                animation: 'fadeIn 1s ease-in-out',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                    transform: 'translateY(-5px)',
                },
            }}
        >
            <Typography variant="h4" align="center" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                Convert Your Transcript
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: 'center' }}>
                <TextField
                    select
                    label="Select Your Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{ 
                        width: '75%',
                        backgroundColor: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                        }
                    }}
                >
                    {countries.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    select
                    label="Select Grading System"
                    value={system}
                    onChange={(e) => setSystem(e.target.value)}
                    variant="outlined"
                    fullWidth
                    sx={{ 
                        width: '75%',
                        backgroundColor: '#f9f9f9',
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '8px',
                        }
                    }}
                >
                    {gradingSystems.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <Button
                    variant="contained"
                    component="label"
                    color={file ? "success" : "primary"}
                    sx={{ 
                        textTransform: 'none', 
                        fontWeight: 'bold', 
                        width: '75%', 
                        borderRadius: '8px', 
                        backgroundColor: file ? '#4caf50' : '#1976d2',
                        '&:hover': {
                            backgroundColor: file ? '#388e3c' : '#115293',
                        }
                    }}
                >
                    {file ? `Uploaded: ${file.name}` : 'Upload Your Transcript'}
                    <input type="file" hidden onChange={handleUpload} />
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit}
                    disabled={!file}
                    sx={{ 
                        textTransform: 'none', 
                        fontWeight: 'bold', 
                        width: '75%', 
                        borderRadius: '8px',
                        backgroundColor: '#ff5722',
                        '&:hover': {
                            backgroundColor: '#e64a19',
                        },
                    }}
                >
                    Submit for Conversion
                </Button>
            </Box>

            {isConverting && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress color="primary" />
                    <Typography variant="body1" sx={{ ml: 2, color: '#555' }}>
                        Please wait, we are converting your transcript...
                    </Typography>
                </Box>
            )}

            {conversionComplete && (
                <Box sx={{ textAlign: 'center', mt: 4, animation: 'fadeIn 0.5s ease-in-out' }}>
                    <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
                    <Typography variant="h6" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
                        Conversion Complete!
                    </Typography>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ 
                            mr: 2, 
                            textTransform: 'none', 
                            borderRadius: '8px',
                            '&:hover': {
                                backgroundColor: '#0d47a1',
                            },
                        }}
                    >
                        View Transcript
                    </Button>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        sx={{ 
                            textTransform: 'none', 
                            borderRadius: '8px',
                            '&:hover': {
                                borderColor: '#0d47a1',
                                color: '#0d47a1',
                            },
                        }}
                    >
                        Download Transcript
                    </Button>
                </Box>
            )}
        </Box>
    );
};

const Home = () => {
    return (
        <Box>
            {/* Hero Section */}
            <Box 
                sx={{ 
                    backgroundImage: 'url(/hero-image.jpg)', // Replace with a relevant image
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '60vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#fff',
                    textAlign: 'center',
                    padding: 4,
                }}
            >
                <Container>
                    <Typography variant="h2" gutterBottom>
                        Welcome to Grading System Converter
                    </Typography>
                    <Typography variant="h5" paragraph>
                        Convert your academic transcripts to different grading systems effortlessly.
                    </Typography>
                    <Button variant="contained" color="primary" component={Link} to="/services">
                        Get Started
                    </Button>
                </Container>
            </Box>

            {/* Transcript Upload Section */}
            <Box sx={{ py: 8, bgcolor: '#f0f0f0' }}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom>
                        Upload Your Transcript for Evaluation
                    </Typography>
                    <Typography variant="body1" align="center" paragraph>
                        Easily convert your grades to any system. Just upload your transcript, and we’ll do the rest.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <TranscriptUpload />
                    </Box>
                </Container>
            </Box>

            {/* Updated Why Choose Us Section */}
            <Box sx={{ py: 8, bgcolor: '#f0f0f0' }}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom>
                        Why Choose Us?
                    </Typography>
                    <Typography variant="body1" align="center" paragraph>
                        Our converter is accurate, fast, and user-friendly, ensuring you get the best results.
                    </Typography>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                        <Box
                            sx={{
                                maxWidth: '300px',
                                textAlign: 'center',
                                p: 2,
                                backgroundColor: '#ffffff',
                                borderRadius: '12px',
                                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                                    transform: 'translateY(-5px)',
                                },
                                animation: 'fadeIn 1s ease-in-out',
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>Accuracy</Typography>
                            <Typography variant="body2">
                                Our system is highly accurate, giving you reliable results every time.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                maxWidth: '300px',
                                textAlign: 'center',
                                p: 2,
                                backgroundColor: '#ffffff',
                                borderRadius: '12px',
                                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                                    transform: 'translateY(-5px)',
                                },
                                animation: 'fadeIn 1.5s ease-in-out',
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>Speed</Typography>
                            <Typography variant="body2">
                                Get your converted transcript in just a few seconds.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                maxWidth: '300px',
                                textAlign: 'center',
                                p: 2,
                                backgroundColor: '#ffffff',
                                borderRadius: '12px',
                                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease-in-out',
                                '&:hover': {
                                    boxShadow: '0 15px 30px rgba(0, 0, 0, 0.2)',
                                    transform: 'translateY(-5px)',
                                },
                                animation: 'fadeIn 2s ease-in-out',
                            }}
                        >
                            <Typography variant="h6" sx={{ mb: 2 }}>Ease of Use</Typography>
                            <Typography variant="body2">
                                Our user-friendly interface makes the process simple and straightforward.
                            </Typography>
                        </Box>
                    </Box>
                </Container>
            </Box>

            {/* Footer Section */}
            <Box sx={{ py: 4, bgcolor: '#333', color: '#fff', textAlign: 'center' }}>
                <Container>
                    <Typography variant="body2">
                        © 2024 Grading System Converter. All rights reserved.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
};

export default Home;
