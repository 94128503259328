// src/pages/Services.js
import React from 'react';
import { Box, Container, Grid, Paper, Typography, Button } from '@mui/material';
import Fade from 'react-reveal/Fade';

const Services = () => {
    return (
        <Box sx={{ py: 8 }}>
            <Container>
                <Typography variant="h3" align="center" gutterBottom>
                    Our Services
                </Typography>
                <Grid container spacing={4}>
                    {/* Transcript Conversion */}
                    <Grid item xs={12} md={4}>
                        <Fade bottom>
                            <Paper 
                                elevation={3} 
                                sx={{ 
                                    p: 4, 
                                    textAlign: 'center',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                    }
                                }}
                            >
                                <Typography variant="h5" gutterBottom>Transcript Conversion</Typography>
                                <Typography variant="body2" paragraph>
                                    Convert your academic transcript to the grading system of another country.
                                </Typography>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    href="/transcript-conversion"
                                >
                                    Convert Now
                                </Button>
                            </Paper>
                        </Fade>
                    </Grid>

                    {/* Admission Assessment */}
                    <Grid item xs={12} md={4}>
                        <Fade bottom>
                            <Paper 
                                elevation={3} 
                                sx={{ 
                                    p: 4, 
                                    textAlign: 'center',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                    }
                                }}
                            >
                                <Typography variant="h5" gutterBottom>Admission Assessment</Typography>
                                <Typography variant="body2" paragraph>
                                    Assess your admission chances by providing your CV, SOP, LORs, and other relevant documents.
                                </Typography>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    href="/admission-assessment"
                                >
                                    Assess Now
                                </Button>
                            </Paper>
                        </Fade>
                    </Grid>

                    {/* Admission Help */}
                    <Grid item xs={12} md={4}>
                        <Fade bottom>
                            <Paper 
                                elevation={3} 
                                sx={{ 
                                    p: 4, 
                                    textAlign: 'center',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                                    }
                                }}
                            >
                                <Typography variant="h5" gutterBottom>Admission Help</Typography>
                                <Typography variant="body2" paragraph>
                                    Get help with preparing and submitting your application for admission.
                                </Typography>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    href="/admission-help"
                                >
                                    Get Help
                                </Button>
                            </Paper>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Services;
