// src/components/Footer.js
import React from 'react';
import { Typography, Box } from '@mui/material';

const Footer = () => {
    return (
        <Box component="footer" sx={{ p: 2, backgroundColor: '#333', color: '#fff', textAlign: 'center', marginTop: 'auto' }}>
            <Typography variant="body1">
                &copy; {new Date().getFullYear()} Grading System Converter. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
